import React from "react";
import { graphql, StaticQuery } from "gatsby";
import PostComponent from '../components/postComponent'
import Layout from '../components/Layout'
import PostStyle from './posts.module.css'
import cover from '../assets/company-logo-og.jpg';
import SEO from '../components/seo'

function Posts() {
    return (
        <StaticQuery
            query={graphql`
                query PostsQuery {
                    posts: allMdx(filter: {fields: {isBlog: {eq: true}}}, sort: {fields: frontmatter___date, order: DESC}) {
                        edges {
                            node {
                                fields {
                                    slug
                                }
                                excerpt
                                frontmatter {
                                    date
                                    title
                                    subtitle
                                    image {
                                        publicURL
                                    }
                                    keyword
                                }
                            }
                        }
                    }
                    site: site {
                        siteMetadata {
                          siteUrl
                        }
                    }
                }
            `}
            render = {({posts, site}) => {
                
                // console.log(post.frontmatter.keyword.split(', '))
                return(
                    
                    <Layout>
                        <SEO
                            title={"Posts"}
                            keywords={[`sonic instruments`, `s-board`, `mo-band`, `audio`, `plugin`, `digital audio`, `music technology`]}
                            description={"News from Sonic Instruments"}
                            image={cover}
                        />
                        <div className={PostStyle.cover} />
                        <div className={PostStyle.background} />
                        <div className={PostStyle.container}>
                            <h1 style={{textAlign: 'center'}}>Posts</h1>
                            <div className={PostStyle.postsContainer}>
                                {posts.edges.map(({ node: post }) => 
                                (
                                    <PostComponent 
                                        title={post.frontmatter.title}
                                        subtitle={post.frontmatter.subtitle}
                                        date={post.frontmatter.date}
                                        excerpt={post.excerpt}
                                        img={post.frontmatter.image.publicURL}
                                        key={post.frontmatter.keyword}
                                        siteUrl={site.siteMetadata.siteUrl}
                                        slug={post.fields.slug}
                                    />
                                ))}
                            </div>
                        </div>
                    </Layout>
                )
            }}
        />
    )
}

export default Posts;