import React from "react";
import PostStyle from './postComponent.module.css'
import Share from './share'
import { Link } from "gatsby";

function PostComponent(props) {
    return (
        <div className={PostStyle.container}>
            <Link to={props.slug}>
                <div className={PostStyle.cover} style={{backgroundImage: `url(${props.img})`}}>
                    <h3 className={PostStyle.title}>{props.title}</h3>
                    <h4 className={PostStyle.subtitle}>{props.subtitle}</h4>
                    <p className={PostStyle.date}>{props.date}</p>
                </div>
            <div className={PostStyle.contentContainer}>
                <p className={PostStyle.excerpt}>{props.excerpt}</p>
                <div className={PostStyle.shareButtons}>
                    <Share 
                        socialConfig= {{
                            twitterHandle: '@sonic_inst',
                            config: {
                            url: `${props.siteUrl}${props.slug}`,
                            title: props.title,
                            },
                        }}
                        subtitle={props.subtitle}
                        tags={['MO-Band', 'SonicInstruments', 'MusicTech']}
                    />
                </div>
            </div>
            </Link>
        </div>
    )
}

export default PostComponent;